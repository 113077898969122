import React, { FC } from 'react';
import CustomerCard from '@/components/dashboard/communication-center/components/customer-card/customer-card';
import { useAppSelector } from '@/lib/state/hooks';
import { useGetRecentActivityListQuery } from '@/lib/state/services/business-intelligence-overview-api';
import { Customer } from '@/lib/types/communication-center';
import { Card, Divider } from '@mui/material';
import Link from 'next/link';
import { UnresolvedIssuesHeader } from './unresolved-issues-header';
import UnresolvedIssuesEmptyState from '@/components/dashboard/business-intelligence/intelligence-overview/components/unresolved-issues/unresolved-issues-empty-state';
import { UserInterface } from '@/lib/types/user-interface';
export const UnresolvedIssuesComponent: FC<React.PropsWithChildren<unknown>> = props => {
  const user = useAppSelector(state => state.auth.user) as UserInterface;
  const {
    data: customers,
    isLoading
  } = useGetRecentActivityListQuery();
  let assignedItemsCount = 0;
  let itemsToReview = 0;
  const customerData = customers?.data;
  if (customerData) {
    const withAssigned = customerData?.filter((customer: Customer) => {
      return customer?.current_assignment?.user_id === user?.id;
    });
    assignedItemsCount = withAssigned?.length ?? 0;
    itemsToReview = customerData?.length;
  }
  return <Card sx={{
    boxShadow: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }} {...props} data-sentry-element="Card" data-sentry-component="UnresolvedIssuesComponent" data-sentry-source-file="unresolved-issues-component.tsx">
      <UnresolvedIssuesHeader itemsToReview={itemsToReview} assignedItemsCount={assignedItemsCount} data-sentry-element="UnresolvedIssuesHeader" data-sentry-source-file="unresolved-issues-component.tsx" />
      <Divider data-sentry-element="Divider" data-sentry-source-file="unresolved-issues-component.tsx" />
      <div className="grow overflow-auto">
        {!isLoading && customerData && customerData.length > 0 ? customerData.map((customer: Customer) => <CustomerCard key={customer.id} customer={customer} isLoading={true} />) : <UnresolvedIssuesEmptyState />}
      </div>
      <button className="w-full py-3 text-orange-500 border-t font-proximaSemiBold hover:underline">
        <Link href={'/dashboard/communication-center'} data-sentry-element="Link" data-sentry-source-file="unresolved-issues-component.tsx">
          Communication Theater
        </Link>
      </button>
    </Card>;
};