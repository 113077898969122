import { FC } from 'react';
import { Card } from '@mui/material';
import { BusinessIntelligenceOverviewDonutChart } from './business-intelligence-overview-donut-chart';
import { SectionTitleDescriptionComponent } from '@/components/_shared/page-elements/section-header';
import { BusinessIntelligenceOverviewNpsPercentages } from './business-intelligence-overview-nps-percentages';
import { NpsDonutTimeframeDataValue, npsMapTypeData } from '@/lib/types/rtk-types/chart-data';
import { useGetNpsDonutQuery } from '@/lib/state/services/business-intelligence-overview-api';
import { useAppSelector } from '@/lib/state/hooks';
import { useCalculateNpsScores, useNpsPromoterScore } from '@/lib/hooks/use-nps-promoter-percentage';
interface Props {
  netPromoterScoreComponentProps?: NetPromoterScoreComponentProps;
}
export interface NetPromoterScoreComponentProps {
  npsCountAndPercentages?: npsMapTypeData | null;
}
export const NetPromoterScoreComponent: FC<React.PropsWithChildren<Props>> = ({
  netPromoterScoreComponentProps
}) => {
  const {
    data: npsData,
    isLoading
  } = useGetNpsDonutQuery();
  const timeframe_status = useAppSelector(state => state.timeframe.status);
  const npsDataTimeframe: NpsDonutTimeframeDataValue = npsData?.data?.[timeframe_status] as NpsDonutTimeframeDataValue;
  const npsDataTimeFrameWithoutNullValuesArray = npsDataTimeframe && npsDataTimeframe?.response_breakdown && npsDataTimeframe?.response_breakdown.map(value => {
    if (value.choice_value === null && value?.count && npsDataTimeframe?.total_responses && npsDataTimeframe?.total_responses !== 0) {
      return {
        ...npsDataTimeframe,
        total_responses: npsDataTimeframe?.total_responses - value?.count
      };
    } else {
      return npsDataTimeframe;
    }
  });
  const npsDataTimeFrameWithoutNullValuesObject = npsDataTimeFrameWithoutNullValuesArray ? npsDataTimeFrameWithoutNullValuesArray[0] : npsDataTimeframe;
  const npsCountAndPercentages = useNpsPromoterScore(npsDataTimeFrameWithoutNullValuesObject);
  const npsDataHandler = (npsCountAndPercentages: npsMapTypeData) => {
    const percentage: number[] = [];
    const count: number[] = [];
    for (const value in npsCountAndPercentages) {
      percentage.push(npsCountAndPercentages[value]?.percentage);
      count.push(npsCountAndPercentages[value]?.count);
    }
    return {
      count,
      percentage
    };
  };
  const series1 = npsCountAndPercentages && npsDataHandler(npsCountAndPercentages)?.count;
  const series2 = npsCountAndPercentages && npsDataHandler(npsCountAndPercentages)?.percentage;
  const npsScore = useCalculateNpsScores(npsDataTimeFrameWithoutNullValuesObject);
  const donutChartData = {
    series1: series1,
    series2: series2,
    npsScore: npsScore
  };
  return <>
      <Card sx={{
      height: '100%',
      boxShadow: 1,
      px: 2
    }} data-sentry-element="Card" data-sentry-source-file="net-promoter-score-component.tsx">
        <SectionTitleDescriptionComponent title="Net Promoter Score" data-sentry-element="SectionTitleDescriptionComponent" data-sentry-source-file="net-promoter-score-component.tsx" />

        <div className="flex items-center justify-evenly">
          <div className="w-full">
            <BusinessIntelligenceOverviewNpsPercentages npsCountAndPercentages={npsCountAndPercentages} data-sentry-element="BusinessIntelligenceOverviewNpsPercentages" data-sentry-source-file="net-promoter-score-component.tsx" />
          </div>

          <div className="-mt-10 w-1/3">
            <BusinessIntelligenceOverviewDonutChart donutChartData={donutChartData} isLoading={isLoading} npsDataTimeframe={npsDataTimeframe} data-sentry-element="BusinessIntelligenceOverviewDonutChart" data-sentry-source-file="net-promoter-score-component.tsx" />
          </div>
        </div>
      </Card>
    </>;
};