import { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BusinessIntelligenceOverviewDonutChartOptions } from './business-intelligence-overview-donut-chart-options';
import { NpsDonutChartPlaceholder } from '@/components/_shared/nps-donut-chart-placeholder';
import { NpsDonutTimeframeDataValue } from '@/lib/types/rtk-types/chart-data';
import { SidebarContext } from '@/components/_shared/layouts/dashboard/dashboard-userbar/sidebar-context';
interface Props {
  donutChartData: {
    series1: number[] | null;
    series2: number[] | null;
    npsScore: number | undefined;
  };
  isLoading: boolean;
  npsDataTimeframe: NpsDonutTimeframeDataValue;
}
export const BusinessIntelligenceOverviewDonutChart: FC<React.PropsWithChildren<Props>> = ({
  donutChartData,
  isLoading,
  npsDataTimeframe
}) => {
  const {
    isSidebarOpen
  } = useContext(SidebarContext);
  const series1 = donutChartData?.series1;
  const series2 = donutChartData?.series2;
  const npsScore = donutChartData?.npsScore;
  const [isEmpty, setIsEmpty] = useState(true);
  useEffect(() => {
    const responses = npsDataTimeframe?.total_responses as number ?? 0;
    if (isLoading || responses === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [npsDataTimeframe, isLoading]);
  return <div className="h-full" data-sentry-component="BusinessIntelligenceOverviewDonutChart" data-sentry-source-file="business-intelligence-overview-donut-chart.tsx">
      {isSidebarOpen && <>
          {!isEmpty ? <HighchartsReact highcharts={Highcharts} options={BusinessIntelligenceOverviewDonutChartOptions({
        series1,
        series2,
        npsScore
      })} /> : <NpsDonutChartPlaceholder />}
        </>}
      {!isSidebarOpen && <>
          {!isEmpty ? <HighchartsReact highcharts={Highcharts} options={BusinessIntelligenceOverviewDonutChartOptions({
        series1,
        series2,
        npsScore
      })} /> : <NpsDonutChartPlaceholder />}
        </>}
    </div>;
};