// React & Utilities
import type { FC } from 'react';

// Material UI
import { Card, Grid } from '@mui/material';

// Page Content, Sections & Components
import SingleStatCard from '@/components/_shared/stat-cards/single-stat-card';
import { useGetNewCustomersStatCardQuery, useGetTotalCustomersStatCardQuery, useGetTotalPurchasesStatCardQuery, useGetTotalSalesStatCardQuery } from '@/lib/state/services/business-intelligence-overview-api';
import { useAppSelector } from '@/lib/state/hooks';
export const BusinessIntelligenceStatCards: FC<React.PropsWithChildren<unknown>> = () => {
  //call redux store to get timeframe status
  const timeframe_status = useAppSelector(state => state.timeframe.status) as 'seven_day' | 'four_week' | 'six_month';
  //get data for stat cards from RTK Query [total sales, total customers, new customers, total purchases]
  const {
    data: totalSalesStatCard
  } = useGetTotalSalesStatCardQuery();
  const {
    data: totalCustomerStatCard
  } = useGetTotalCustomersStatCardQuery();
  const {
    data: totalPurchasesStatCard
  } = useGetTotalPurchasesStatCardQuery();
  const {
    data: newCustomersStatCard
  } = useGetNewCustomersStatCardQuery();

  //set the data as a variable depending on the timeframe status
  const totalSalesStatCardData = totalSalesStatCard?.data?.[timeframe_status];
  const totalCustomerStatCardData = totalCustomerStatCard?.data?.[timeframe_status];
  const totalPurchasesStatCardData = totalPurchasesStatCard?.data?.[timeframe_status];
  const newCustomersStatCardData = newCustomersStatCard?.data?.[timeframe_status];

  //create an array of objects to map over and create the stat cards
  const biStatCards = [{
    statCard: 'business_intelligence_overview_total_sales',
    statLabel: totalSalesStatCard?.title,
    statValue: ((totalSalesStatCardData?.current ?? 0) / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    }),
    statDelta: totalSalesStatCardData?.delta,
    statPrevious: ((totalSalesStatCardData?.previous ?? 0) / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    }),
    showDelta: true,
    timeFrameSetting: timeframe_status,
    statPreviousShow: true
  }, {
    statCard: 'business_intelligence_overview_total_purchases',
    statLabel: totalPurchasesStatCard?.title,
    statValue: totalPurchasesStatCardData?.current,
    statDelta: totalPurchasesStatCardData?.delta,
    statPrevious: totalPurchasesStatCardData?.previous,
    showDelta: true,
    asCurrency: false,
    timeFrameSetting: timeframe_status,
    statPreviousShow: true
  }, {
    statCard: 'business_intelligence_overview_new_customers',
    statLabel: newCustomersStatCard?.title,
    statValue: newCustomersStatCardData?.current,
    statDelta: newCustomersStatCardData?.delta,
    statPrevious: newCustomersStatCardData?.previous,
    showDelta: true,
    asCurrency: false,
    timeFrameSetting: timeframe_status,
    statPreviousShow: true
  }, {
    statCard: 'business_intelligence_overview_total_customers',
    statLabel: totalCustomerStatCard?.title,
    statValue: totalCustomerStatCardData?.current,
    statDelta: totalCustomerStatCardData?.delta,
    statPrevious: totalCustomerStatCardData?.previous,
    showDelta: true,
    asCurrency: false,
    timeFrameSetting: timeframe_status,
    statPreviousShow: true
  }];

  //map over the array of objects and create the stat cards

  return <Card sx={{
    marginX: '1rem'
  }} data-sentry-element="Card" data-sentry-component="BusinessIntelligenceStatCards" data-sentry-source-file="business-intelligence-overview-stat-cards.tsx">
      <Grid alignItems="center" container justifyContent="space-between" data-sentry-element="Grid" data-sentry-source-file="business-intelligence-overview-stat-cards.tsx">
        {biStatCards.map(value => <Grid key={value.statCard} item md={3} sm={6} xs={12} sx={{
        borderRight: theme => ({
          md: `1px solid ${theme.palette.divider}`
        }),
        borderBottom: theme => ({
          md: 'none',
          xs: `1px solid ${theme.palette.divider}`
        }),
        p: 1,
        textAlign: 'center'
      }}>
            <SingleStatCard statCard={value.statCard} statLabel={value.statLabel} statValue={value.statValue} statDelta={value.statDelta} statPrevious={value.statPrevious} showDelta={value.showDelta} asCurrency={value.asCurrency} timeFrameSetting={value.timeFrameSetting} statPreviousShow={value.statPreviousShow} />
          </Grid>)}
      </Grid>
    </Card>;
};