import React from 'react';
import { Customer } from '@/lib/types/communication-center';
import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import { updateActiveCustomerId, ActiveCustomer, setActiveCustomer } from '@/lib/state/slices/communication-slice';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useGetCustomerMutation } from '@/lib/state/services/communication-theater-api';
import CustomerAvatar from './customer-avatar';
import { CustomerLatestMessage } from './customer-latest-message';
import { CustomerName } from './customer-name';
import { CustomerAssignedAgent } from './customer-assigned-agent';
import { CustomerLatestTimeDate } from './customer-latest-time-date';
import { CustomerAssignedStatus } from './customer-assigned-status';
import { ChatbotAITimelineIcon } from '@/assets/icons/chatbot-ai-timeline-icon';
import { EmailTimelineIcon } from '@/assets/icons/email-timeline-icon';
import { Phone } from '@/assets/icons/phone';
import { TextTimelineIcon } from '@/assets/icons/text-timeline-icon';
type Props = {
  customer: Customer;
  isLoading: boolean;
};
const CustomerCard = ({
  customer,
  isLoading
}: Props) => {
  const dispatch = useAppDispatch();
  const chosenCustomer = useAppSelector(state => state.communication.activeCustomer);
  const router = useRouter();
  const displayLinkToContactProfile = router.pathname === '/dashboard/business-intelligence/intelligence-overview';
  const activeCardStyle = `bg-${chosenCustomer?.customer?.id === customer.id ? 'orange-50 hover:bg-gray-500 hover:bg-opacity-10' : 'white'} flex items-center justify-between h-20 py-2 px-4 border-b border-gray-100 cursor-pointer${chosenCustomer?.customer?.id !== customer.id ? ' hover:bg-gray-500 hover:bg-opacity-10' : ''}`;
  const iconToDisplay = () => {
    switch (customer?.recent_activity) {
      case 'Email':
        return <EmailTimelineIcon />;
      case 'SMS':
        return <TextTimelineIcon />;
      case 'Voice':
        return <Phone />;
      case 'Chat':
        return <ChatbotAITimelineIcon />;
      default:
        return '';
    }
  };
  const displayContactCard = <div className={activeCardStyle}>
      <div className="w-full">
        <div className="flex items-center w-full">
          <CustomerAvatar firstName={customer.first_name} lastName={customer.last_name} id={customer.id} isLoading={isLoading} avg_spend_per_visit_for_store_range={customer.avg_spend_per_visit_for_store_range} />
          <div className="flex justify-between w-full">
            <div className="w-full ml-2">
              <div className="flex items-center w-full ">
                <div className="flex items-center">
                  <CustomerName customer={customer} isLoading={isLoading} />
                  <CustomerAssignedAgent customer={customer} isLoading={isLoading} />
                </div>
              </div>
              <div className="flex items-center">
                <div className="pr-2">{iconToDisplay()}</div>
                <CustomerLatestMessage most_recent_message_body={customer.most_recent_message_body} isLoading={isLoading} />
              </div>
            </div>

            <div className="flex flex-col items-end justify-center w-1/2 ml-2 text-xs">
              <CustomerLatestTimeDate customer={customer} isLoading={isLoading} />

              <CustomerAssignedStatus customer={customer} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>;
  const [getActiveCustomer] = useGetCustomerMutation();
  const handleActiveCustomerSelect = async (customer: Customer): Promise<void> => {
    if (!customer) return;
    const id = customer?.id as number;
    const res = (await getActiveCustomer(id)) as {
      data?: ActiveCustomer;
    };
    const responseObject = res.data;
    dispatch(setActiveCustomer(responseObject));
    dispatch(updateActiveCustomerId(id));
  };
  return <>
      {displayLinkToContactProfile ? <NextLink href={`/dashboard/all-contacts/${customer.id}`} onClick={() => {
      handleActiveCustomerSelect(customer);
    }} key={customer.id}>
          {displayContactCard}
        </NextLink> : <div key={customer.id} onClick={() => {
      handleActiveCustomerSelect(customer);
    }}>
          {displayContactCard}
        </div>}
    </>;
};
export default CustomerCard;